import "moment/locale/nl";

import {PrimitiveType} from "intl-messageformat";
import moment from "moment";
import {createIntl, createIntlCache, IntlConfig, IntlShape} from "react-intl";

import Storage from "../ts/services/system/storage/Storage";
import {StorageKeys} from "../ts/services/system/storage/StorageKeys";

import {Injectable} from "../ts/IoC/Injectable";
import {Gender, LanguageTag} from "@sense-os/goalie-js";
import {AppConfig} from "../ts/app/AppConfig";
import autobind from "autobind-decorator";
import strTranslation from "../assets/lang/strings";

// The next two conditionals, Intl.PluralRules and Intl.RelativeTimeFormat, are needed
// because the new version of react-intl doesn't provide locale data anymore and encourage us
// to just use what modern browser provides now. But, in case the browser doesn't support it yet,
// we add the following polyfills.
// For more details: https://github.com/formatjs/react-intl/blob/master/docs/Upgrade-Guide.md#migrate-to-using-native-intl-apis
if (!Intl.PluralRules) {
	require("@formatjs/intl-pluralrules/polyfill");
	require("@formatjs/intl-pluralrules/dist/locale-data/nl");
}

if (!(Intl as any).RelativeTimeFormat) {
	require("@formatjs/intl-relativetimeformat/polyfill");
	require("@formatjs/intl-relativetimeformat/dist/locale-data/nl");
}

// It is optional, but react-intl encourage us to add this caching mechanism,
// as it helps prevent memory leak.
// For more details: https://github.com/formatjs/react-intl/blob/master/docs/API.md#createintl
const cache = createIntlCache();

/**
 * The interface contains methods for app localization
 */
export interface ILocalization {
	/**
	 * Initialize localisation `_intl` object based on `locale()`
	 */
	init(): void;

	/**
	 * React Intl instance.
	 * Try to use React-intl API outside or React component
	 * get `intl` context from `IntlProvider`.
	 * @see https://github.com/yahoo/react-intl/wiki/API for full documentation about `react-intl`
	 */
	intl: IntlShape;

	/**
	 * React Intl Props to be injected in IntlProvider and
	 * intl property
	 */
	intlProps: Pick<IntlConfig, "locale" | "messages">;

	/**
	 *  Return a translation
	 * @param key
	 * @returns {string}
	 */
	translate(key: any): string;

	/**
	 * This function will return a formatted message string. It expects a MessageDescriptor with at least an id property,
	 * and accepts a shallow values object which are used to fill placeholders in the message.
	 * For more details @See https://github.com/yahoo/react-intl/wiki/API
	 * @param id of the string to be localised
	 * @param {{[p: string]: ReactIntl.MessageValue}} values
	 * @returns {string}
	 */
	formatMessage(id: string, values?: {[key: string]: PrimitiveType}): string;

	/**
	 * Returns the currently selected locale
	 * @returns {LanguageTag}
	 */
	getLocale(): LanguageTag;

	/**
	 * Returns true if the given translation `id` is exist on the localization message
	 * @param id
	 */
	isTranslationIdExist(id: string): boolean;
}

interface TranslationObject {
	[key: string]: string;
}

/**
 * This is a localization service which returns a localized string for a key
 */
@autobind
class Localization implements ILocalization, Injectable {
	/** React intl instance */
	private _intl: IntlShape;

	public readonly c: string = "[Localization]";

	/** @inheritDoc */
	public init(): void {
		this._intl = createIntl(this.intlProps, cache);
		moment.locale(this.getLocale());
	}

	public constructor() {
		this.init();
	}

	/**
	 * Return languages object from json
	 */
	public get languages(): Record<string, Record<string, string>> {
		return {
			en: require("../assets/lang/en.json"),
			nl: require("../assets/lang/nl.json"),
		};
	}

	/** @inheritDoc */
	public get intl(): IntlShape {
		return this._intl;
	}

	/** @inheritDoc */
	public get intlProps(): Pick<IntlConfig, "locale" | "messages"> {
		return {
			locale: this.getLocale(),
			messages: this.languages[this.getLocale()],
		};
	}

	/** @inheritDoc */
	public formatMessage(id: string, values?: {[key: string]: PrimitiveType}): string {
		return this._intl.formatMessage({id: id}, values);
	}

	/** @inheritDoc */
	public translate(key: any): string {
		const GENDERS: TranslationObject = {
			[Gender.MALE]: this.formatMessage(strTranslation.COMMON.gender.male), //"Male"
			[Gender.FEMALE]: this.formatMessage(strTranslation.COMMON.gender.female), //"Female"
			[Gender.UNDEFINED]: this.formatMessage(strTranslation.COMMON.gender.unspecified), //"Unspecified"
			[Gender.OTHER]: this.formatMessage(strTranslation.COMMON.gender.other), //"Other"
			[Gender.AGENDER]: this.formatMessage(strTranslation.COMMON.gender.agender), //"Agender"
			[Gender.I_DONT_KNOW]: this.formatMessage(strTranslation.COMMON.gender.idk), //"I Don't Know"
			[Gender.NON_BINARY]: this.formatMessage(strTranslation.COMMON.gender.nonbinary), //"Non-binary"
			[Gender.PREFER_NOT_TO_SAY]: this.formatMessage(strTranslation.COMMON.gender.prefer_not_say), //"prefer not to say"
			[Gender.TRANSMASCULINE]: this.formatMessage(strTranslation.COMMON.gender.transmasculine), //"Transmasculine"
			[Gender.TRANSFEMININE]: this.formatMessage(strTranslation.COMMON.gender.transfeminine), //""Transfeminine"
		};

		return GENDERS[key];
	}

	/** @inheritDoc */
	public getLocale(): LanguageTag {
		/** default browser language and convert it to browser locale */
		const browserLocale = navigator.language.substr(0, 2);

		/** locale string from localStorage. If empty, get from default browser language */
		const locale = <LanguageTag>Storage.read(StorageKeys.LOCALE) || browserLocale;

		// Check if we have locale's translation
		if (this.languages[locale]) {
			return <LanguageTag>locale;
		}

		// In case everything else failed, return the default locale
		return AppConfig.DEFAULT_LOCALE;
	}

	/** @inheritdoc */
	public isTranslationIdExist(id: string): boolean {
		const {messages} = this.intlProps;
		return messages && !!messages[id];
	}
}

const localization = new Localization();
export default localization;
